function initVideoPlayer() {
  document.querySelectorAll(".videoplayer").forEach((video) => {
    // Override all clicks to start the videoplayer, except for the watch-on-yt link
    video.addEventListener("click", (e) => {
      e.stopPropagation();
      video.querySelector(".video-frame").innerHTML = video.dataset.html;
      e.preventDefault();
    });
    video.querySelectorAll(".watch-on-yt").forEach((a) => {
      a.addEventListener("click", (e) => e.stopPropagation());
    });
  });
}

// bulk initialize all components
document.body
  ? initVideoPlayer()
  : addEventListener("DOMContentLoaded", initVideoPlayer, { once: true });
